import React, { useState } from 'react';
import { Box, Heading, Text } from 'rebass';
import { gql } from 'apollo-boost';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';

import { Layout } from '../../pos/layout';
import { BoxitModalProvider } from '../../pos/components/Modal';
import BoxScanningPanel from '../../pos/components/BoxScanningPanel';
import CodeConfirmPanel from '../../pos/components/CodeConfirmPanel';
import BoxAdminPanel from '../../pos/components/BoxAdminPanel/BoxAdminPanel';
import BoxCompletedPanel from '../../pos/components/BoxCompletedPanel/BoxCompletedPanel';
import BoxitModal from '../../pos/components/Modal';
import { FooterButton, HomeNavBtn } from '../../pos/components/Buttons'

import { phoneNumberMask } from '../../utils';

const UPDATE_BOX_TRACKING_INFO = gql`
 mutation UpdateTracking( $stationId: String!, $boxIds: [ID!],  $status: String!, $validation: String! ){
  updateBoxTrackingInfo(
    stationId: $stationId
    boxIds: $boxIds
    status: $status
    validation: $validation
  ){
    status
    history{
      status
    }
  } 
}`;

const REQUEST_SF_PIN = gql`
  mutation RequestPin( $phone: String!, $email: String!){
    requestPin(phone: $phone, email:$email)
  }
`;


const VALIDATE_SF_PIN = gql`
  query ValidatePin( $phone: String!, $email:String!, $pin: String!){
    validatePin(phone: $phone, email: $email, pin: $pin)
  } 
`;


export default function PickYourBoxPage({location}) {
  const stationData = location.state && location.state.station ? location.state.station : {};
  const [step, setStep] = useState(1);
  const [stationState, setStationState] = useState(stationData);
  const [scannedBoxes, setScannedBoxes] = useState([]);
  const [confirmedBoxIds, setConfirmedBoxIds] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [pin, setPin] = useState(null);
  const [disablePinInput, setDisablePinInput] = useState(false);

  const [requestPin] = useMutation(REQUEST_SF_PIN, {
    onCompleted: data => {
      console.log('requesting done', data)
      if(data && data.requestPin){
        handleNextStep();
      }else{
        openErrorModal('Error sending pin')
      }
    },
  });

  const [validatePin] = useLazyQuery(VALIDATE_SF_PIN, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if(data.validatePin){
        handleNextStep();
      }else{
        openErrorModal('Wrong Pin')
      }
      setDisablePinInput(false)
    },
    onError: (err) => {
      openErrorModal('Wrong Pin')
      setDisablePinInput(false)
    },
  });
  

  const handleBoxAdd = boxes => {
    setScannedBoxes(boxes);
  };

  const handleBackStep = () => {
    setStep(step - 1);
    setPin(null);
    setConfirmedBoxIds([]);
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const openErrorModal = (msg)=>{
    setErrorMsg(msg);
    setErrorModalOpen(true);
  }

  const closeErrorModal = (msg)=>{
    setDisablePinInput(false)
    setErrorMsg(null);
    setErrorModalOpen(false);
  }

  const onSetPin = pin => {
    setDisablePinInput(true)
    if(stationState.pin === pin.toString(10)){
      setDisablePinInput(false)
      setPin(pin.toString(10));
      handleNextStep();
    }else{
      console.log('error', 'pins do not match');
      openErrorModal('pins do not match')
    }
    
  }



  const handleUserPin = pin => {
    setDisablePinInput(true)
    validatePin({ variables: { 
      phone: scannedBoxes[0].recipient.phone, 
      email: scannedBoxes[0].recipient.email, 
      pin } });
    
  }

  const [updateTrackingInfo] = useMutation(UPDATE_BOX_TRACKING_INFO, {
    onError: error => {
      console.error(`Box creation failed! ${JSON.stringify(error)}`);
      openErrorModal('Box creation failed!')
    },
    onCompleted: async data => {
      handleNextStep();
    }
  });

  const updateConfirmedBoxIds = (boxId, isConfirmed)=>{
    console.log(isConfirmed)
      if(isConfirmed){
        setConfirmedBoxIds([...confirmedBoxIds, boxId]);
      }else{
        setConfirmedBoxIds(confirmedBoxIds.filter(id => id !== boxId));
      }
  }

  const boxTrackingUpdate = () => {
    updateTrackingInfo({
      variables: {
        stationId: stationState._id,
        status: "delivered",
        boxIds: confirmedBoxIds,
        validation: "pickupPoint"
      },
    });
  }

  const sendSFPin =()=>{
    requestPin({
      variables: {
        phone: scannedBoxes[0].recipient.phone,
        email: scannedBoxes[0].recipient.email,
      },
    });
  }

  const handleBoxScanning = (data)=>{
    if(data.status !== "dropped"){
      return {err:`Καταχώριση δεν επιτρέπεται: ${data.status}`}
    }

    if(data.pickupPoint._id !== stationState._id){
      return {err:`Καταχώριση δεν επιτρέπεται: Wrong Station`}
    }

    const filteredExist = scannedBoxes.filter(box=> box._id === data._id)
    if(filteredExist.length !== 0){
      return {err:`Καταχώριση δεν επιτρέπεται: Added already`}
    }

    const filtered = scannedBoxes.filter(box=> box.recipient.phone !== data.recipient.phone)
    if(filtered.length !== 0){
      return {err:`Καταχώριση δεν επιτρέπεται: Wrong Number`}
    }
    
    const boxes = scannedBoxes.concat([data]);
    handleBoxAdd(boxes);

    return true
    
  }

  const Footer = () => (
    <>
      {step === 1 && (
        <Text
          fontSize="22px"
          fontWeight="600"
          lineHeight="1.5"
          color="blues.peacock"
        >
          {scannedBoxes && scannedBoxes.length > 0
            ? `Έχετε εισάγει ${scannedBoxes.length} δέματα προς παραλαβή.`
            : 'Δεν έχετε εισάγει δέματα προς παραλαβή.'}
        </Text>
      )}

      {step === 5 || step === 4 && (
        <HomeNavBtn text="ΕΞΟΔΟΣ" />
      )}

      {step > 1 && step < 4 && (
        <FooterButton onClick={handleBackStep}>ΕΠΙΣΤΡΟΦΗ</FooterButton>
      )}
      <Box mx="auto" />
      {(scannedBoxes.length !== 0 && step === 1) && (
        <FooterButton onClick={sendSFPin}>ΕΠΟΜΕΝΟ</FooterButton>
      )}

      {( confirmedBoxIds.length > 0 &&  step === 4 ) && (
        <FooterButton onClick={boxTrackingUpdate}>ΕΠΟΜΕΝΟ</FooterButton>
      )}

      {step === 5 && (
        <Heading fontSize="40px" lineHeight="0.63" color="blues.peacock">
          weboxit.com
        </Heading>
      )}
    </>
  );

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <BoxScanningPanel
            addButtonText="ΠΑΡΑΛΑΒΗ ΔΕΜΑΤΟΣ"
            helpText="Για να προσθέσετε νέο Δέμα επιλέξτε 'ΠΡΟΣΘΗΚΗ ΔΕΜΑΤΟΣ' και
            χρησιμοποιήστε το scanner που διαθέτει ο Σταθμός."
            scannedBoxes={scannedBoxes}
            handleBoxScanning={handleBoxScanning}
          />
        );
      case 2:
        return (
          <CodeConfirmPanel
            title="Τα δέματά σας είναι έτοιμα προς παραλαβή!"
            description1={`Έχει αποσταλεί sms με τον κωδικό επαλήθευσης στον αριθμό ${phoneNumberMask(scannedBoxes[0].recipient.phone)}`}
            description2="Παρακαλούμε πληκτρολογήστε τον κωδικό επαλήθευσης."
            buttonText="ΕΠΑΛΗΘΕΥΣΗ ΑΠΟ ΠΑΡΑΛΗΠΤΗ"
            onSetPin={handleUserPin}
            disablePinInput={disablePinInput}
          />
        );
      case 3:
        return (
          <CodeConfirmPanel
            title="Μπορείτε να παραλάβετε τα δέματα σας!"
            description1="Παρακαλούμε, απευθυνθείτε στον υπάλληλο του πρακτορείου AEGEAN OIL που διαχειρίζεται τον συγκεκριμένο σταθμό, για να παραδώσει τα δέματα σας."
            buttonText="ΕΙΣΟΔΟΣ ΔΙΑΧΕΙΡΙΣΤΗ ΣΤΑΘΜΟΥ WEBOXIT"
            onSetPin={onSetPin}
            disablePinInput={disablePinInput}
          />
        );
      case 4:
        return (
        <BoxAdminPanel 
          scannedBoxes={scannedBoxes}
          updateConfirmedBoxIds={updateConfirmedBoxIds}
        />
        );
      case 5:
        return (
          <BoxCompletedPanel
            infoText="Μπορείτε τώρα να παραλάβετε τα δέματα σας, 
        από τον διαχειριστή αυτού του Σταθμού."
          />
        );
    }
  };

  return (
    <Layout footer={() => <Footer />}>
      <BoxitModalProvider>
        <BoxitModal
              isOpen={errorModalOpen}
              onBackgroundClick={() => closeErrorModal()}
              onEscapeKeydown={() => closeErrorModal()}
            >
              <Text>
                {errorMsg}
              </Text>
        </BoxitModal>
        {renderStep()}
      </BoxitModalProvider>
    </Layout>
  );
}
